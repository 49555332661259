import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import Logo from "../../content/assets/logo.png"

import Tilt from "react-tilt"
import Particles from "react-particles-js"
import "./layout.css"

import particlesConfig from "../../content/assets/particles.json"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          textAlign: "center",
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Tilt
          className="Tilt"
          options={{ max: 30, perspective: 1000 }}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 50,
            height: 200,
            width: 200,
          }}
        >
          <div className="Tilt-inner">
            <img style={{ width: 200, height: 200 }} src={Logo} />
          </div>
        </Tilt>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <div>
            <Tilt
              className="Tilt"
              options={{ max: 45, perspective: 1000 }}
              style={{ height: 50, width: 50 }}
            >
              <div className="Tilt-inner">
                <img style={{ width: 50, height: 50 }} src={Logo} />
              </div>
            </Tilt>
          </div>
        </Link>
      </h3>
    )
  }
  return (
    <>
      <Particles
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          top: 0,
          backgroundSize: "cover",
        }}
        params={particlesConfig}
      />
      <div
        style={{
          background: "#ffffff",
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          <p style={{ float: "left", color: "#aaa" }}>
            © {new Date().getFullYear()} &nbsp;&nbsp;
          </p>
          <p style={{ float: "left", color: "#aaa", clear: "right" }}>
            Swift Guild by JP McGlone, LLC
          </p>
        </footer>
      </div>
    </>
  )
}

export default Layout
